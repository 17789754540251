import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { request } from "../../../utility/toolRequest"
const api = `${process.env.REACT_APP_API_URL}/api/tools`
export const getAllOffersChange = createAsyncThunk("offerChangeSlice/getAllOffersChange", (filter) => {
  const response = request.get(`${api}/gift-offer-change?offer_id=${filter.offerId}&page=${filter?.page ?? ""}`)
  return response
})

export const getOfferChangeDetail = createAsyncThunk("offerChangeSlice/getOfferChangeDetail", (offerId) => {
  const response = request.get(`${api}/gift-offer-change/${offerId}`)
  return response
})

const offerChangeSlice = createSlice({
  name: "offerChangeSlice",
  initialState: {
    offersChange: [],
    offerChangeDetail: {},
    gettingOffers: false,
    gettingOfferDetail: false,
    currentPage: 1,
    lastPage: 1
  },
  reducers: {
    changeOfferDetail: (state, action) => {
      state.offerChangeDetail = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllOffersChange.fulfilled, (state, action) => {
        state.offersChange = action.payload.data.data.items
        state.currentPage = action.payload.data.data.current_page
        state.lastPage = action.payload.data.data.last_page
        state.gettingOffers = false
      })
      .addCase(getAllOffersChange.pending, (state) => {
        state.gettingOffers = true
      })
      .addCase(getOfferChangeDetail.fulfilled, (state, action) => {
        state.offerChangeDetail = action.payload.data.data
        state.gettingOfferDetail = false
      })
      .addCase(getOfferChangeDetail.pending, (state) => {
        state.gettingOfferDetail = true
      })
  }
})
export default offerChangeSlice.reducer

export const { changeOfferDetail } = offerChangeSlice.actions
