import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { request } from "../../../utility/toolRequest"
const api = `${process.env.REACT_APP_API_URL}/api/tools`
export const getAllSettingsChange = createAsyncThunk("settingChangeSlice/getAllSettingsChange", (filter) => {
  const response = request.get(`${api}/shop-setting-change?shop_domain=${filter.shopDomain}&page=${filter?.page ?? ""}`)
  return response
})

export const getSettingChangeDetail = createAsyncThunk("settingChangeSlice/getSettingChangeDetail", (settingId) => {
  const response = request.get(`${api}/shop-setting-change/${settingId}`)
  return response
})

const settingChangeSlice = createSlice({
  name: "settingChangeSlice",
  initialState: {
    settingsChange: [],
    settingChangeDetail: {},
    gettingSettings: false,
    gettingSettingDetail: false,
    currentPage: 1,
    lastPage: 1
  },
  reducers: {
    changeSettingDetail: (state, action) => {
      state.settingChangeDetail = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSettingsChange.fulfilled, (state, action) => {
        state.settingsChange = action.payload.data.data.items
        state.currentPage = action.payload.data.data.current_page
        state.lastPage = action.payload.data.data.last_page
        state.gettingSettings = false
      })
      .addCase(getAllSettingsChange.pending, (state) => {
        state.gettingSettings = true
      })
      .addCase(getSettingChangeDetail.fulfilled, (state, action) => {
        state.settingChangeDetail = action.payload.data.data
        state.gettingSettingDetail = false
      })
      .addCase(getSettingChangeDetail.pending, (state) => {
        state.gettingSettingDetail = true
      })
  }
})
export default settingChangeSlice.reducer

export const { changeSettingDetail } = settingChangeSlice.actions
